import { deepMerge, getNestedPropertyForSetting } from '@/utils/helpers'
import { isEqual } from 'lodash'
import { mapActions } from 'vuex'

export default {
  props: {
    step: {
      type: Object,
      default: () => {
        return null
      }
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      properties: {}
    }
  },
  watch: {
    properties: {
      handler(val) {
        this.$emit('changeStepProperties', { ...val })
      },
      deep: true
    },
    'step.properties': {
      handler(val) {
        const newProperties = deepMerge(this.properties, val)

        if (!isEqual(newProperties, this.properties)) {
          this.$set(this, 'properties', newProperties)
        }
      },
      deep: true
    }
  },
  created() {
    const properties = deepMerge(this.properties, this.step.properties)

    this.properties = properties
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    handleChange(key, val) {
      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }
      this.$set(this.properties, key, val)
    }
  }
}